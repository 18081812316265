import React from 'react'

const Landing = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>
                Joony
            </h1>
            Joony's many projects
        </div>
    )
}

export default Landing